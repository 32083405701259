<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

interface TestForm {
  iso: string;
  picturestyle: string;
  aperture: string;
  whitebalance: string;
  exposurecompensation: string;
  imageformat: string;

  [key: string]: string | undefined;
}

const schema = yup.object({
  iso: yup.string(),
  picturestyle: yup.string(),
  aperture: yup.string(),
  whitebalance: yup.string(),
  exposurecompensation: yup.string(),
  imageformat: yup.string(),
});

const { handleSubmit } = useForm<TestForm>({
  validationSchema: schema,
});

const { value: isoValue } = useField<string | undefined>('iso', 'iso');
const { value: pictureStyleValue } = useField<string | undefined>('picturestyle', 'picturestyle');
const { value: apertureValue } = useField<string | undefined>('aperture', 'aperture');
const { value: whitebalanceValue } = useField<string | undefined>('whitebalance', 'whitebalance');
const { value: exposureCompensationValue } = useField<string | undefined>(
  'exposurecompensation',
  'exposurecompensation',
);
const { value: imageFormatValue } = useField<string | undefined>('imageformat', 'imageformat');

const timedOutTimeLimitSecs = ref<number>(10);
const timedOutTimeSecs = ref<number>(0);

const desiredIso = ref<string>();
const desiredPictureStyle = ref<string>();
const desiredAperture = ref<string>();
const desiredWhitebalance = ref<string>();
const desiredExposureCompensation = ref<string>();
const desiredImageFormat = ref<string>();

const reportedIso = ref<string>();
const reportedPictureStyle = ref<string>();
const reportedAperture = ref<string>();
const reportedWhitebalance = ref<string>();
const reportedExposureCompensation = ref<string>();
const reportedImageFormat = ref<string>();

const shadow = ref<client.Shadow>();

const route = useRoute();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
const fetchSettingsIsLoading = ref<boolean>(false);
let shadowInterval: NodeJS.Timeout | null = null;

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  timedOutTimeSecs.value = 0;
  Object.keys(values).forEach((key) => values[key] === undefined && delete values[key]);

  if (!applicationStore.activeOrganisation) return;

  var desired = shadow?.value?.state?.reported ?? {};
  desired['iso'] = values.iso;
  desired['picturestyle'] = values.picturestyle;
  desired['aperture'] = values.aperture;
  desired['whitebalance'] = values.whitebalance;
  desired['exposurecompensation'] = values.exposurecompensation;
  desired['imageformat'] = values.imageformat;
  try {
    shadow.value = await client.updateCameraByIdShadow({
      cameraId,
      requestBody: {
        state: {
          desired: desired,
        },
      },
    });
    // textarea.value!.value = JSON.stringify(shadow.value, null, 2);
    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Settings Updated',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
});

async function fetchShadow(): Promise<void> {
  shadow.value = await client.getCameraByIdShadow({ cameraId });

  // Log the shadow for testing

  // const textAreaValue = textarea.value;
  // if (textAreaValue != null) textAreaValue.value = JSON.stringify(shadow.value, null, 2);

  if (shadow.value.state.desired != undefined) {
    desiredIso.value = shadow.value.state.desired['iso'] as string; // The type on these should at least be a string.
    desiredPictureStyle.value = shadow.value.state.desired['picturestyle'] as string; // The type on these should at least be a string.
    desiredAperture.value = shadow.value.state.desired['aperture'] as string; // The type on these should at least be a string.
    desiredWhitebalance.value = shadow.value.state.desired['whitebalance'] as string; // The type on these should at least be a string.
    desiredExposureCompensation.value = shadow.value.state.desired['exposurecompensation'] as string; // The type on these should at least be a string.
    desiredImageFormat.value = shadow.value.state.desired['imageformat'] as string; // The type on these should at least be a string.
  }
  if (shadow.value.state.reported != undefined) {
    reportedIso.value = shadow.value.state.reported['iso'] as string;
    reportedPictureStyle.value = shadow.value.state.reported['picturestyle'] as string;
    reportedAperture.value = shadow.value.state.reported['aperture'] as string;
    reportedWhitebalance.value = shadow.value.state.reported['whitebalance'] as string;
    reportedExposureCompensation.value = shadow.value.state.reported['exposurecompensation'] as string;
    reportedImageFormat.value = shadow.value.state.reported['imageformat'] as string;
  }

  isoValue.value = isoValue.value ?? reportedIso.value;
  pictureStyleValue.value = pictureStyleValue.value ?? reportedPictureStyle.value;
  apertureValue.value = apertureValue.value ?? reportedAperture.value;
  whitebalanceValue.value = whitebalanceValue.value ?? reportedWhitebalance.value;
  exposureCompensationValue.value = exposureCompensationValue.value ?? reportedExposureCompensation.value;
  imageFormatValue.value = imageFormatValue.value ?? reportedImageFormat.value;
}

// Using JSON.stringify to deeply compare the reported vs desired states
const reportedNotEqualToDesired = computed(() => {
  return JSON.stringify(shadow.value?.state.reported) !== JSON.stringify(shadow.value?.state.desired);
});

// Manual refresh logic for the button
const fetchSettings = async (): Promise<void> => {
  fetchSettingsIsLoading.value = true;
  try {
    await fetchShadow();

    if (reportedNotEqualToDesired.value) {
      // Notify the user that changes are still pending
      applicationStore.publishErrorNotification({
        text: 'The camera may be offline, please try again later.',
        autoCloseMs: 3000,
      });
    } else {
      applicationStore.publishSuccessNotification({
        text: 'Settings refreshed.',
        autoCloseMs: 3000,
      });
    }
  } finally {
    fetchSettingsIsLoading.value = false;
  }
};

onMounted(async () => {
  await fetchShadow();
  // Fetch the shadow every 1 second
  // shadowInterval = setInterval(async () => {
  //   await fetchShadow();
  //   if (shadow.value?.state.desired != undefined) {
  //     timedOutTimeSecs.value++;
  //   } else {
  //     timedOutTimeSecs.value = 0;
  //   }
  // }, 1000);
});

onUnmounted(() => {
  if (shadowInterval) clearInterval(shadowInterval);
});

// Get current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <section v-else>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Camera Settings
            </Heading>
            <p>Update the technical aspects of the camera.</p>
          </div>

          <div class="fields">
            <!-- Row 1: Current Settings and Pending Settings Side by Side -->
            <div class="row-half">
              <!-- Left Column: Non-Editable Current Settings (show only if there's a pending change) -->
              <div v-show="reportedNotEqualToDesired" class="field">
                <h3>Current Settings</h3>
                <!-- Current ISO -->
                <div>
                  <label>Current ISO</label>
                  <v-select v-model="reportedIso"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
                </div>
                <!-- Current Picture Style -->
                <div>
                  <label>Current Picture Style</label>
                  <v-select v-model="reportedPictureStyle"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
                </div>
                <!-- Current Aperture -->
                <div>
                  <label for="model">Current Aperture</label>
                  <v-select v-model="reportedAperture"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true" />
                </div>
                <!-- Current White Balance -->
                <div>
                  <label for="model">Current White Balance</label>
                  <v-select v-model="reportedWhitebalance"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true" />
                </div>
                <!-- Current Exposure Compensation -->
                <div>
                  <label for="model">Current Exposure Compensation</label>
                  <v-select v-model="reportedExposureCompensation"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true" />
                </div>
                <!-- Current Image Format -->
                <div>
                  <label for="model">Current Image Format</label>
                  <v-select v-model="reportedImageFormat"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true" />
                </div>
              </div>

              <!-- Left Column: Editable Current Settings (show when reported === desired) -->
              <div v-show="!reportedNotEqualToDesired" class="field">
                <h3>Current Settings</h3>
                <!-- Current ISO -->
                <div>
                  <label>Current ISO</label>
                  <v-select v-model="isoValue"
                            :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
                </div>
                <!-- Current Picture Style -->
                <div>
                  <label for="model">Current Picture Style</label>
                  <v-select v-model="pictureStyleValue"
                            :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
                </div>
                <!-- Current Aperture -->
                <div>
                  <label for="model">Current Aperture</label>
                  <v-select v-model="apertureValue"
                            :options="['5.6', '6.3', '7.1', '8.0']" />
                </div>
                <!-- Current White Balance -->
                <div>
                  <label for="model">Current White Balance</label>
                  <v-select v-model="whitebalanceValue"
                            :options="['Auto', 'Daylight', 'Cloudy', 'Tungsten']" />
                </div>
                <!-- Current Exposure Compensation -->
                <div>
                  <label for="model">Current Exposure Compensation</label>
                  <v-select v-model="exposureCompensationValue"
                            :options="['0']" />
                </div>
                <!-- Current Image Format -->
                <div>
                  <label for="model">Current Image Format</label>
                  <v-select v-model="imageFormatValue"
                            :options="['Large Fine JPEG', 'Large Normal JPEG', 'RAW']" />
                </div>
              </div>

              <!-- Right Column: Pending Settings (show when pending change) -->
              <div v-show="reportedNotEqualToDesired" class="field">
                <h3>Pending Settings</h3>
                <!-- Pending ISO -->
                <div>
                  <label>Pending ISO</label>
                  <v-select v-model="desiredIso"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Auto', '100', '200', '400', '800', '1600', '3200', '6400']" />
                </div>
                <!-- Pending Picture Style -->
                <div>
                  <label>Pending Picture Style</label>
                  <v-select v-model="desiredPictureStyle"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Neutral', 'Standard', 'Portrait', 'Landscape']" />
                </div>
                <!-- Pending Aperture -->
                <div>
                  <label for="model">Pending Aperture</label>
                  <v-select v-model="desiredAperture"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['5.6', '6.3', '7.1', '8.0']" />
                </div>
                <!-- Pending White Balance -->
                <div>
                  <label for="model">Pending White Balance</label>
                  <v-select v-model="desiredWhitebalance"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Auto', 'Daylight', 'Cloudy', 'Tungsten']" />
                </div>
                <!-- Pending Exposure Compensation -->
                <div>
                  <label for="model">Pending Exposure Compensation</label>
                  <v-select v-model="desiredExposureCompensation"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['0']" />
                </div>
                <!-- Pending Image Format -->
                <div>
                  <label for="model">Pending Image Format</label>
                  <v-select v-model="desiredImageFormat"
                            :clearable="false"
                            :disabled="true"
                            :no-drop="true"
                            :options="['Large Fine JPEG', 'Large Normal JPEG', 'RAW']" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ButtonActions>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :is-block-btn="true"
                           :type="ButtonType.Button"
                           :loading="fetchSettingsIsLoading"
                           @click="fetchSettings">
            Refresh Settings
          </ButtonComponent>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :loading="isSubmitting"
                           :disabled="reportedNotEqualToDesired"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Update Settings
          </ButtonComponent>
        </ButtonActions>
      </form>
    </section>
  </ContainerCard>
</template>
