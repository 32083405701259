<script lang="ts" setup>
import { useRoute } from 'vue-router';
import ContainerCard from '@components/cards/ContainerCard.vue';
import CameraScheduleSection from '@components/camera/CameraScheduleSection.vue';

const route = useRoute();
const cameraId = route.params['id'] as string;
</script>

<template>
  <section>
    <ContainerCard>
      <CameraScheduleSection :camera-id="cameraId" />
    </ContainerCard>
  </section>
</template>
