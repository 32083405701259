<script lang="ts" setup>
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { AlertVariant, ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import * as yup from 'yup';
import { useApplicationStore } from '@stores/application';
import { computed, onMounted, ref, watchEffect } from 'vue';
import * as client from '@gabrielcam/api-client';
import Heading from '@components/Heading.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import AlertBanner from '@components/AlertBanner.vue';
import Loading from '@components/Loading.vue';
import timezones, { TimeZone } from 'timezones-list';

type schedule = {
  "job": "capture",
  "cron": string,
  "timezone": TimeZone,
  "active": boolean
}

enum Interval {
  Hours, Minutes, Seconds
}

const props = defineProps<{ cameraId: string }>();
const applicationStore = useApplicationStore();
const shadow = ref<client.Shadow>();
const reported = ref<schedule>();
const desired = ref<schedule>();
const cronExpression = ref<string>();
const cronActive = ref<boolean>();
const isSubmitting = ref<boolean>(false);
const isFetching = ref<boolean>(false);
const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
const captureRates = [
  { frequency: 5, label: "5 seconds", interval: Interval.Seconds },
  { frequency: 10, label: "10 seconds", interval: Interval.Seconds },
  { frequency: 15, label: "15 seconds", interval: Interval.Seconds },
  { frequency: 20, label: "20 seconds", interval: Interval.Seconds },
  { frequency: 25, label: "25 seconds", interval: Interval.Seconds },
  { frequency: 30, label: "30 seconds", interval: Interval.Seconds },
  { frequency: 1, label: "1 minute", interval: Interval.Minutes },
  { frequency: 2, label: "2 minutes", interval: Interval.Minutes },
  { frequency: 5, label: "5 minutes", interval: Interval.Minutes },
  { frequency: 10, label: "10 minutes", interval: Interval.Minutes },
  { frequency: 15, label: "15 minutes", interval: Interval.Minutes },
  { frequency: 20, label: "20 minutes", interval: Interval.Minutes },
  { frequency: 30, label: "30 minutes", interval: Interval.Minutes },
  { frequency: 1, label: "1 hour", interval: Interval.Hours }
];
const daysOfWeek = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" }
];

const { handleSubmit, defineField, setFieldValue } = useForm({
  initialValues: {
    startHour: '07',
    endHour: '19',
    captureRate: { frequency: 10, label: "10 minutes", interval: Interval.Minutes },
    selectedDays: [],
    timezone: 'Europe/London',
  },
  validationSchema: yup.object({
    startHour: yup.number().required('Please specify an Start Hour.')
      .test(
        'is-less-than-end',
        'Start Hour must be earlier than the End Hour.',
        function (value) {
          const { endHour } = this.parent;
          return Number(value) <= Number(endHour);
        }
      ),
    endHour: yup.number().required('Please specify an End Hour.')
      .test(
        'is-greater-than-start',
        'End Hour must be later than the Start Hour.',
        function (value) {
          const { startHour } = this.parent;
          return Number(value) >= Number(startHour);
        }
      ),
  }),
});

const [startHourValue] = defineField('startHour');
const [endHourValue] = defineField('endHour');
const [timezoneValue] = defineField('timezone');
const [captureRateValue] = defineField('captureRate');
const [selectedDaysValue] = defineField('selectedDays');

const onSubmit = handleSubmit(async (values): Promise<void> => {
  isSubmitting.value = true;
  try {
    shadow.value = await client.updateCameraByIdShadow({
      cameraId: props.cameraId,
      requestBody: {
        state: {
          desired: {
            schedule: [
              {
                job: 'capture',
                cron: cronExpression.value,
                timezone: values.timezone,
                active: cronActive.value,
              },
            ],
          },
        },
      },
    })
    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Update request sent.',
      autoCloseMs: 3000,
    });
  } catch {
    applicationStore.publishErrorNotification({
      text: 'Error while updating.'
    });
    isSubmitting.value = false;
    return;
  }
});

const captureScheduleState = (schedules: schedule[]): schedule | undefined => schedules.find((schedule: schedule) => schedule.job === 'capture')
const fetchShadow = async (): Promise<void> => {
  isFetching.value = true;
  shadow.value = await client.getCameraByIdShadow({ cameraId: props.cameraId })
  isFetching.value = false;
}

watchEffect(() => {
  if (shadow.value?.state.desired && 'schedule' in shadow.value?.state.desired) {
    desired.value = captureScheduleState(shadow.value.state.desired['schedule'] as schedule[]);
  }
  if (shadow.value?.state.reported && 'schedule' in shadow.value?.state.reported) {
    reported.value = captureScheduleState(shadow.value.state.reported['schedule'] as schedule[]);
  }
  const cronValue = desired.value || reported.value;

  if (cronValue) {
    const cronParts = cronValue.cron.split(' ')
    if (cronParts[1]) {
      const secondsField = cronParts[0];
      const minuteField = cronParts[1];

      if (secondsField && secondsField !== '0') {
        const findSeconds = secondsField.replace('*/', '');
        const matched = captureRates.find((rate) => rate.frequency.toString() === findSeconds && rate.interval == Interval.Seconds)
        setFieldValue('captureRate', matched!);
      } else {
        let setMinutes;
        if (minuteField !== '0') {
          const findMinutes = minuteField.replace('*/', '');
          setMinutes = captureRates.find((rate) => rate.frequency.toString() === findMinutes && rate.interval == Interval.Minutes)
        } else {
          setMinutes = { frequency: 1, label: "1 hour", interval: Interval.Hours };
        }
        setFieldValue('captureRate', setMinutes!);
      }
    }

    if (cronParts[2]) {
      const hourField = cronParts[2];
      let startHour;
      let endHour;
      if (hourField?.includes('-')) {
        const splitHours = hourField.split('-');
        startHour = splitHours[0]?.padStart(2, '0');
        endHour = splitHours[1]?.padStart(2, '0');
      } else {
        startHour = hourField?.padStart(2, '0');
        endHour = hourField?.padStart(2, '0');
      }

      setFieldValue('startHour', startHour!);
      setFieldValue('endHour', endHour!);
    }

    if (cronParts[5]) {
      const dayOfWeekField = cronParts[5];
      const daysArray = dayOfWeekField === '*'
        ? []
        : dayOfWeekField.split(',').map(String);
      setFieldValue('selectedDays', daysArray as never);
    }
  }
})

watchEffect(() => {
  if (!(captureRateValue.value && startHourValue.value && endHourValue.value)) {
    return;
  }

  const secondField = captureRateValue.value.interval === Interval.Seconds
    ? `*/${Number(captureRateValue.value.frequency)}`
    : '0';

  let minuteField

  if (captureRateValue.value.interval === Interval.Seconds) {
    minuteField = '*'
  }

  if (captureRateValue.value.interval === Interval.Minutes) {
    minuteField = `*/${Number(captureRateValue.value.frequency)}`;
  }

  if (captureRateValue.value.interval === Interval.Hours) {
    minuteField = '0';
  }

  const hourField = startHourValue.value === endHourValue.value
    ? Number(startHourValue.value)
    : `${Number(startHourValue.value)}-${Number(endHourValue.value)}`;

  const dayOfMonthField = '*';
  const monthField = '*';

  const dayOfWeekField = selectedDaysValue.value?.length
    ? selectedDaysValue.value.toString()
    : '*';

  cronActive.value = !!selectedDaysValue.value?.length;
  cronExpression.value = `${secondField} ${minuteField} ${hourField} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`
  console.group('info');
  console.log(`cron: ${cronExpression.value}`);
  console.log(`active: ${cronActive.value}`);
  console.groupEnd();
})

onMounted(async () => await fetchShadow())
const isPending = computed((): boolean => !!desired.value && JSON.stringify(reported.value) != JSON.stringify(desired.value));
</script>

<template>
  <div class="container-card">
    <AlertBanner v-if="isPending"
                 :variant="AlertVariant.Warning"
                 :has-bottom-margin="true"
                 :icon-name="IconName.InformationCircleIcon"
                 :icon-style="IconStyle.Outline">
      <template #mainContent>
        <span class="text--size-5">
          <Heading level="5">
            Changes are pending
          </Heading>
          The settings below have been sent to the device and are awaiting confirmation that they have been accepted.
          Click refresh to check if the update has been applied.
        </span>
      </template>
    </AlertBanner>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Camera Schedule
          </Heading>
          <p>
            Choose the days, times & rate that you'd like the camera to take photos.
          </p>
        </div>

        <Loading v-if="isFetching" />

        <div v-else class="fields">
          <div class="row-half">
            <div class="field">
              <label for="timezone">Timezone</label>
              <v-select id="timezone"
                        v-model="timezoneValue"
                        :clearable="false"
                        :disabled="isPending"
                        :reduce="(timezone: TimeZone) => timezone.tzCode"
                        :options="timezones" />
              <ErrorMessage name="timezone" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row-quarter">
            <div class="field">
              <label for="start-hour">Start Hour</label>
              <!-- Start Hour -->
              <v-select id="start-hour"
                        v-model="startHourValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="hours" />
              <ErrorMessage name="startHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- End Hour -->
              <label for="end-hour">End Hour</label>
              <v-select id="end-hour"
                        v-model="endHourValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="hours" />
              <ErrorMessage name="endHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- Capture Rate -->
              <label for="capture-rate">Capture Rate</label>
              <v-select id="end-hour"
                        v-model="captureRateValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="captureRates" />
              <ErrorMessage name="captureRate" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row">
            <div v-for="(day, index) in daysOfWeek" :key="index" class="checkbox-field">
              <label :for="day.value">{{ day.label }}</label>
              <Field :id="day.value"
                     v-model="selectedDaysValue"
                     :disabled="isPending"
                     name="days"
                     type="checkbox"
                     :value="day.value" />
            </div>
          </div>
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent v-if="isPending"
                         :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :type="ButtonType.Button"
                         :loading="isFetching"
                         :disabled="isFetching"
                         @click="fetchShadow">
          Refresh
        </ButtonComponent>

        <ButtonComponent v-if="!(isPending || isFetching)"
                         :variant="ButtonVariant.Dark"
                         :type="ButtonType.Submit"
                         :loading="isSubmitting"
                         :disabled="isSubmitting || isFetching">
          Update
        </ButtonComponent>
      </ButtonActions>
    </form>
  </div>
</template>

